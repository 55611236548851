import useApi from 'api'
import React from 'react'
import Swal from 'sweetalert2'

const useQuiz = ({ data, content, id, module, course }) => {
	/** Api */
	const { useActions } = useApi()
	const { dispatch, useCoursesActions } = useActions()
	const { actSendQuiz } = useCoursesActions()

	/** States */
	const [question, setQuestion] = React.useState(0)
	const [options, setOptions] = React.useState([])
	const [responses, setResponses] = React.useState([])
	const [messageButton, setMessageButton] = React.useState('Siguiente')
	const [quizSettings, setQuizSettings] = React.useState({})

	const handleGetOptions = (questionToFilter) => {
		const options = ['a', 'b', 'c', 'd', 'e', 'f']
		const result = options.map((option) => ({
			option: questionToFilter[option] === '' ? null : questionToFilter[option],
			response: option,
		}))
		const filterResult = result.filter((option) => option.option !== null)
		setOptions(filterResult)
	}

	const multipleResponseLogic = (option) => {
		if (responses[question] && typeof responses[question] === 'string') {
			const str = responses[question]
			const arrStr = str.split(',')
			if (arrStr.includes(option)) {
				const res = arrStr.filter((pot) => pot !== option)
				setResponses((prevResponses) => {
					prevResponses[question] = res.join(',')
					return [...prevResponses]
				})
			} else {
				arrStr.push(option)
				setResponses((prevResponses) => {
					prevResponses[question] = arrStr.join(',')
					return [...prevResponses]
				})
			}
		} else {
			const arr = [...responses]
			arr.push(option)
			setResponses([...arr])
		}
	}

  const singleResponseLogic = (option) => {
    if (responses[question] && typeof responses[question] === 'string') {
      setResponses((prevRes) => {
        prevRes[question] = option;
        return [...prevRes];
      });
    } else {
      const arr = [...responses]
			arr.push(option)
			setResponses([...arr])
    }
  }

	const handleSelectedOption = (option) => {
		if (data.questions[question].multiple) multipleResponseLogic(option)
    else singleResponseLogic(option);
	}

	const handleGetCalification = () => {
		const { correctResponses, isRequired } = quizSettings
    console.log(responses)
    console.log(correctResponses)
		return responses.filter((item, index) => {
			if (isRequired[index]) {
        const res = correctResponses[index].split(',')
			if (res.every((itm) => item.includes(itm))) return item
      } else {
        return item[0] === correctResponses[index][0  ]
      }
		}).length
	}

	const handleSentQuiz = () => {
		if (quizSettings.totalQuestions > question)
			setQuestion((prevState) => prevState + 1)

		const result = quizSettings.percentByQuestion * handleGetCalification()
    console.log(result)

		if (quizSettings.totalQuestions === question && result < 80) {
			Swal.fire({
				icon: 'error',
				text: 'Revisa tus respuestas y vuelve a enviar el examen.',
			})

			setQuestion(0)
		}

		if (quizSettings.totalQuestions === question && result > 79) {
			dispatch(
				actSendQuiz({
					data: {
						calification: result,
						content,
						user: id,
						module,
						course,
					},
					onError: () =>
						Swal.fire({
							icon: 'error',
							title: 'Ocurrio un problema!',
							text: 'Ocurrio un problema al momento de guardar tu examen, vuelve a intentarlo.',
						}),
					onSuccess: () => {
						Swal.fire({
							icon: 'success',
							title: 'Bien Hecho!',
							text: `Pasaste este examen!. tu nota fue de ${result}%`,
							allowOutsideClick: () => {
								const popup = Swal.getPopup()
								popup.classList.remove('swal2-show')
								setTimeout(() => {
									popup.classList.add(
										'animate__animated',
										'animate__headShake'
									)
								})
								setTimeout(() => {
									popup.classList.remove(
										'animate__animated',
										'animate__headShake'
									)
								}, 500)
								return false
							},
						}).then((res) => {
							res.isConfirmed && window.location.reload()
						})
					},
				})
			)
		}
	}

	const handleGetQuizSettings = (data) => {
		let settings = {}
		const correctResponses = data.questions.map((item) => item.correct)
		const totalQuestions = data.questions.length - 1
		const percentByQuestion = 100 / data.questions.length
    const isRequired = data.questions.map((item) => item.multiple);
		settings = {
			...settings,
			correctResponses,
			totalQuestions,
			percentByQuestion,
      isRequired
		}
		setQuizSettings(settings)
	}

	React.useEffect(() => {
		if (data.questions) {
			handleGetOptions(data.questions[question])
			handleGetQuizSettings(data)
		}
	}, [data])

	React.useEffect(() => {
		if (data.questions) {
			handleGetOptions(data.questions[question])
			if (quizSettings.totalQuestions === question)
				setMessageButton('Enviar')
			else setMessageButton('Siguiente')
		}
	}, [question, data])

	return {
		options,
		responses,
		messageButton,
		question,
		handleSelectedOption,
		handleSentQuiz,
	}
}

export default useQuiz
