import React from "react";

const MessagesIcon = () => {
    return (
        <svg
            width={23}
            height={21}
            viewBox="0 0 23 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.6807 19.8739C17.3507 19.8739 17.0207 19.7967 16.7227 19.6328L12.4543 17.3377C12.0072 17.3281 11.5602 17.2992 11.1344 17.2414C10.847 17.2028 10.6022 17.0291 10.4957 16.7784C10.3893 16.5277 10.4425 16.2577 10.6341 16.0552C11.3366 15.3127 11.6985 14.4256 11.6985 13.4806C11.6985 11.147 9.43129 9.24739 6.64244 9.24739C5.59928 9.24739 4.5987 9.50785 3.75779 10.0093C3.52362 10.1443 3.23622 10.1636 2.98075 10.0575C2.73593 9.95142 2.55499 9.73918 2.52306 9.48846C2.49112 9.21846 2.46983 8.94856 2.46983 8.66892C2.46983 3.88604 7.04692 0 12.6672 0C18.2874 0 22.8645 3.88604 22.8645 8.66892C22.8645 11.2918 21.5233 13.7024 19.1603 15.3513L19.5222 17.9742C19.6073 18.6299 19.288 19.2471 18.6813 19.6039C18.3832 19.7774 18.032 19.8739 17.6807 19.8739ZM12.6565 15.8817C12.8056 15.872 12.9546 15.9107 13.0823 15.9878L17.5423 18.3889C17.6594 18.4564 17.7552 18.4275 17.8191 18.3889C17.8723 18.36 17.9574 18.2828 17.9362 18.1478L17.521 15.1006C17.4891 14.8306 17.6168 14.5703 17.851 14.416C20.0225 13.0371 21.2679 10.9349 21.2679 8.64954C21.2679 4.66709 17.4146 1.42712 12.6672 1.42712C8.10072 1.42712 4.35388 4.43571 4.07713 8.2157C4.87546 7.93606 5.73766 7.78175 6.63179 7.78175C10.3041 7.78175 13.2846 10.3274 13.2846 13.4613C13.2952 14.3099 13.0717 15.1295 12.6565 15.8817Z"
                fill="#292D32"
                fillOpacity="0.4"
            />
            <path
                d="M3.53394 20.7224C3.25718 20.7224 2.99107 20.6549 2.74625 20.5103C2.26726 20.2307 2.0118 19.7485 2.07567 19.2374L2.28854 17.7524C0.851552 16.6917 0 15.12 0 13.471C0 11.5907 1.08574 9.8357 2.90593 8.78463C4.0023 8.13857 5.30091 7.79146 6.65275 7.79146C10.3251 7.79146 13.3055 10.3371 13.3055 13.471C13.3055 14.7439 12.7946 15.9975 11.8579 16.9907C10.6551 18.3117 8.85614 19.0832 6.87628 19.141L4.27905 20.5392C4.04487 20.6646 3.7894 20.7224 3.53394 20.7224ZM6.6421 9.23784C5.59895 9.23784 4.59837 9.4982 3.75746 9.99962C2.39498 10.7903 1.58601 12.0825 1.58601 13.471C1.58601 14.8114 2.30984 16.036 3.58717 16.8267C3.83199 16.981 3.95971 17.2414 3.92778 17.5114L3.6936 19.1603L6.23761 17.791C6.36535 17.7235 6.50372 17.6849 6.6421 17.6849C8.20683 17.6849 9.68643 17.0775 10.6231 16.0457C11.3257 15.2935 11.6982 14.4064 11.6982 13.4614C11.6982 11.1375 9.43096 9.23784 6.6421 9.23784Z"
                fill="#292D32"
                fillOpacity="0.4"
            />
        </svg>
    )
}

export default MessagesIcon;