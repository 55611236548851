import React from "react";

const PlusIcon = () => {
    return (
        <svg
            width={25}
            height={25}
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.5 0C11.0005 0 0 10.7899 0 24.0309C0 37.2719 11.0005 48.0618 24.5 48.0618C37.9995 48.0618 49 37.2719 49 24.0309C49 10.7899 37.9995 0 24.5 0ZM34.3 25.8332H26.3375V33.6432C26.3375 34.6285 25.5045 35.4456 24.5 35.4456C23.4955 35.4456 22.6625 34.6285 22.6625 33.6432V25.8332H14.7C13.6955 25.8332 12.8625 25.0162 12.8625 24.0309C12.8625 23.0456 13.6955 22.2286 14.7 22.2286H22.6625V14.4185C22.6625 13.4333 23.4955 12.6162 24.5 12.6162C25.5045 12.6162 26.3375 13.4333 26.3375 14.4185V22.2286H34.3C35.3045 22.2286 36.1375 23.0456 36.1375 24.0309C36.1375 25.0162 35.3045 25.8332 34.3 25.8332Z"
                fill="#252525"
            />
        </svg>
    );
};

export default PlusIcon;
