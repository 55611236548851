import React from "react";

const VideoIcon = () => {
    return (
        <svg
            width={25}
            height={25}
            viewBox="0 0 52 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M37.7112 38.8219H13.7676C5.53097 38.8219 0 33.6974 0 26.0661V12.7558C0 5.12449 5.53097 0 13.7676 0H37.7112C45.9477 0 51.4787 5.12449 51.4787 12.7558V26.0661C51.4787 33.6974 45.9477 38.8219 37.7112 38.8219ZM13.7676 3.32759C7.59012 3.32759 3.59154 7.03231 3.59154 12.7558V26.0661C3.59154 31.7896 7.59012 35.4943 13.7676 35.4943H37.7112C43.8886 35.4943 47.8872 31.7896 47.8872 26.0661V12.7558C47.8872 7.03231 43.8886 3.32759 37.7112 3.32759H13.7676Z"
                fill="black"
            />
            <path
                d="M22.3626 27.2423C21.6682 27.2423 20.9978 27.087 20.3991 26.7765C19.0103 26.0444 18.1962 24.5581 18.1962 22.7168V16.1504C18.1962 14.3091 18.9864 12.8228 20.3991 12.0908C21.7879 11.3587 23.5834 11.4917 25.2834 12.4456L31.1978 15.7289C32.8259 16.6384 33.7595 17.9916 33.7595 19.4336C33.7595 20.8755 32.8259 22.2287 31.1978 23.1383L25.2834 26.4215C24.3018 26.9539 23.2964 27.2423 22.3626 27.2423ZM22.3626 14.9081C22.2668 14.9081 22.1947 14.9303 22.1468 14.9524C21.9792 15.0412 21.7877 15.4405 21.7877 16.1282V22.6946C21.7877 23.3601 21.9792 23.7594 22.1468 23.8704C22.3384 23.9591 22.7936 23.9147 23.4161 23.5598L29.3299 20.2766C29.9285 19.9438 30.1442 19.611 30.1442 19.4114C30.1442 19.2117 29.9285 18.9012 29.3299 18.5462L23.4161 15.263C22.9851 15.019 22.602 14.9081 22.3626 14.9081Z"
                fill="black"
            />
        </svg>
    );
};

export default VideoIcon;
