
import React from "react";

const CharlaIcon = () => {
    return (
        <svg
            width={25}
            height={25}
            viewBox="0 0 52 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M46.1797 28.6906V33.4724C46.1797 42.3327 41.5617 46.13 34.6348 46.13H11.5449C4.61797 46.13 0 42.3327 0 33.4724V18.2832C0 9.4229 4.61797 5.62561 11.5449 5.62561H18.4719C18.1384 6.69448 17.9588 7.87585 17.9588 9.14162V20.1116C17.9588 22.84 18.7798 25.1465 20.2421 26.7498C21.7045 28.3531 23.8082 29.2532 26.2968 29.2532V33.163C26.2968 34.5975 27.7848 35.4695 28.888 34.6819L36.3024 29.2532H42.9728C44.1273 29.2532 45.2048 29.0563 46.1797 28.6906Z"
                fill="#00427F"
            />
            <path
                opacity="0.4"
                d="M51.3108 9.14162V20.1116C51.3108 24.3026 49.3609 27.453 46.1797 28.6906C45.2048 29.0563 44.1272 29.2532 42.9728 29.2532H36.3023L28.8879 34.6819C27.7848 35.4695 26.2967 34.5975 26.2967 33.163V29.2532C23.8082 29.2532 21.7044 28.3531 20.2421 26.7498C18.7797 25.1465 17.9587 22.84 17.9587 20.1116V9.14162C17.9587 7.87586 18.1383 6.69448 18.4718 5.62561C19.6007 2.13773 22.4741 0 26.2967 0H42.9728C47.9756 0 51.3108 3.65665 51.3108 9.14162Z"
                fill="#00427F"
            />
            <path
                opacity="0.4"
                d="M32.3269 54.1462H25.0151V46.1297C25.0151 44.9765 24.1428 44.0201 23.0909 44.0201C22.039 44.0201 21.1668 44.9765 21.1668 46.1297V54.1462H13.8549C12.8031 54.1462 11.9308 55.1026 11.9308 56.2558C11.9308 57.4091 12.8031 58.3655 13.8549 58.3655H32.3269C33.3787 58.3655 34.251 57.4091 34.251 56.2558C34.251 55.1026 33.3787 54.1462 32.3269 54.1462Z"
                fill="#00427F"
            />
            <path
                d="M34.4795 16.8768C33.4019 16.8768 32.5553 15.9204 32.5553 14.7672C32.5553 13.6139 33.4276 12.6576 34.4795 12.6576C35.5313 12.6576 36.4036 13.6139 36.4036 14.7672C36.4036 15.9204 35.5313 16.8768 34.4795 16.8768Z"
                fill="#00427F"
            />
            <path
                d="M41.69 16.8768C40.6125 16.8768 39.7659 15.9204 39.7659 14.7672C39.7659 13.6139 40.6382 12.6576 41.69 12.6576C42.7419 12.6576 43.6142 13.6139 43.6142 14.7672C43.6142 15.9204 42.7419 16.8768 41.69 16.8768Z"
                fill="#00427F"
            />
            <path
                d="M27.2439 16.8768C26.1663 16.8768 25.3197 15.9204 25.3197 14.7672C25.3197 13.6139 26.192 12.6576 27.2439 12.6576C28.2957 12.6576 29.168 13.6139 29.168 14.7672C29.168 15.9204 28.3214 16.8768 27.2439 16.8768Z"
                fill="#00427F"
            />
        </svg>
    );
};

export default CharlaIcon;